import './bootstrap';
import '../css/app.css';
import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import _ from "lodash";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import ElementPlus from "element-plus";
import ptBr from 'element-plus/es/locale/lang/pt-br'
import 'element-plus/dist/index.css';
import {
    faPlus, faCopy, faPen, faTimes, faEye, faUser, faFileArrowDown, faInfo, faFileArrowUp, faFileImport, faFileExcel, faUserPen, faCircle,
    faArrowRightArrowLeft, faWarning, faDownload, faUpload, faRefresh, faImage, faFileZipper, faTriangleExclamation, faTrashCanArrowUp, faRotateLeft
} from '@fortawesome/free-solid-svg-icons';
import "viewerjs/dist/viewer.css";
import VueViewer from 'v-viewer';
import HighchartsVue from 'highcharts-vue';
import CKEditor from "@ckeditor/ckeditor5-vue";


library.add(faPlus);
library.add(faCopy);
library.add(faPen);
library.add(faTimes);
library.add(faEye);
library.add(faUser);
library.add(faFileArrowDown);
library.add(faInfo);
library.add(faFileArrowUp);
library.add(faFileImport);
library.add(faFileExcel);
library.add(faUserPen);
library.add(faCircle);
library.add(faArrowRightArrowLeft)
library.add(faWarning)
library.add(faDownload);
library.add(faUpload);
library.add(faRefresh);
library.add(faImage);
library.add(faFileZipper);
library.add(faTriangleExclamation);
library.add(faTrashCanArrowUp);
library.add(faRotateLeft);

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        return createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(VueViewer)
            .use(HighchartsVue)
            .use(CKEditor)
            .use(ZiggyVue, Ziggy)
            .use(ElementPlus, {
                locale: ptBr,
            })
            .component('font-awesome-icon', FontAwesomeIcon)
            .mount(el);
    },
    progress: {
        color: '#4B5563',
    },
});
